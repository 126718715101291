// RESULT
export const STRING_OUTP_RESULTS = 'STRING_OUTP_RESULTS';
export const STRING_OUTP_INPUT = 'STRING_OUTP_INPUT';
export const STRING_ERROR_BLOCK = 'STRING_ERROR_BLOCK';
export const STRING_NOTE_BLOCK = 'STRING_NOTE_BLOCK';
export const STRING_WARNING_BLOCK = 'STRING_WARNING_BLOCK';

export const STRING_OUTP_STARTING_POSITION = 'STRING_OUTP_STARTING_POSITION';
export const STRING_OUTP_END_POSITION = 'STRING_OUTP_END_POSITION';
export const STRING_OUTP_RADIAL_CLEARANCE_REDUCTION_AND_AXIAL_DISPLACEMENT =
  'STRING_OUTP_RADIAL_CLEARANCE_REDUCTION_AND_AXIAL_DISPLACEMENT'; // TABLE in Result
export const STRING_OUTP_CHECK_VALUES_FOR_CLEARANCE_CLASSES =
  'STRING_OUTP_CHECK_VALUES_FOR_CLEARANCE_CLASSES'; // TABLE in Result

// STRING_OUTP_MOUNTING_TOOLS_AND_UTILITIES NESTING
export const STRING_OUTP_MOUNTING_TOOLS_AND_UTILITIES =
  'STRING_OUTP_MOUNTING_TOOLS_AND_UTILITIES';

export const STRING_OUTP_ADDITIONAL_TOOLS = 'STRING_OUTP_ADDITIONAL_TOOLS'; // VARIABLE_BLOCK in STRING_OUTP_MOUNTING_TOOLS_AND_UTILITIES
export const STRING_OUTP_HYDRAULIC_NUT = 'STRING_OUTP_HYDRAULIC_NUT'; // VARIABLE_BLOCK in STRING_OUTP_MOUNTING_TOOLS_AND_UTILITIES
export const STRING_OUTP_LOCKNUT = 'STRING_OUTP_LOCKNUT'; // VARIABLE_BLOCK in STRING_OUTP_MOUNTING_TOOLS_AND_UTILITIES
export const STRING_OUTP_SLEEVE_CONNECTORS = 'STRING_OUTP_SLEEVE_CONNECTORS'; // VARIABLE_BLOCK in STRING_OUTP_MOUNTING_TOOLS_AND_UTILITIES
export const STRING_OUTP_PUMPS = 'STRING_OUTP_PUMPS'; // TABLE in STRING_OUTP_MOUNTING_TOOLS_AND_UTILITIES

export const STRING_OUTP_MOUNTING_RECOMMENDATIONS_IDMM_BEARING_SEAT_IDMM_MOUNTING_METHOD =
  'STRING_OUTP_MOUNTING_RECOMMENDATIONS_IDMM_BEARING_SEAT_IDMM_MOUNTING_METHOD';

// Subordinate types
export const BLOCK = 'block';
export const TABLE = 'table';
export const VARIABLE_BLOCK = 'variableBlock';
export const VARIABLE_LINE = 'variableLine';
export const TEXT = 'text';
